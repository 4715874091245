@import 'fonts.scss';

.tagContainer {
  position: relative;
  :global {
    .react-tagsinput {
      padding-right: 30px;
      min-height: 34px;
      padding-top: 0px;
      &:hover {
        cursor: pointer;
      }
      > span {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;
        height: 100%;
        .react-tagsinput-tag {
          cursor: default;
        }
      }
      &.disabled:hover {
        cursor: default;
      }
    }

    .react-tagsinput--focused {
      border-color: $theme1;
    }

    .react-tagsinput-tag {
      @include base-font;
      background-color: $white2;
      border: 1px solid #98969d;
      color: $black;
      margin-right: 5px;
      min-width: 84px;
      min-height: 24px;
      padding-top: 1px;
      padding-bottom: 0px;
      padding-left: 7px;
      position: relative;
      padding-right: 25px;
      flex-shrink: 0;
    }

    .react-tagsinput-tag a::before {
      content: unset;
    }

    a:not([href]):not([tabindex]), :global(.react-tagsinput-remove) {
      position: absolute;
      right: 6px;
      color: $theme2;

      &:hover {
        color: $theme1;
      }

      &.disabled {
        color: $super-light;
        cursor: not-allowed;
      }
    }

    a:not([href]):not([tabindex]) {
      top: 0px;
    }

    :global(.react-tagsinput-remove) {
      top: 5px;
    }

    .react-tagsinput .react-tagsinput-input {
      min-height: 24px;
      padding: 0px 5px;

      &::placeholder {
        padding-left: 4px;
      }
    }

    .react-tagsinput-input {
      flex-grow: 1;
      border: none;
      &:hover {
        border: none;
      }
    }

    .include {
      background-color: $light-success;
    }

    .exclude {
      background-color: $light-danger;
    }

    .preferred {
      background-color: $light-info;
    }

    .nonPreferred {
      background-color: $light-warning;
    }

    .limitaionPlus {
      position: absolute;
      right: 0px;
      top: 3px;
      color: $dark;

      &:hover {
        color: $dark;
      }
    }

    .showPlus {
      display: inline;
    }

    .noshowPlus {
      display: none;
    }
  }
  :global(.dropdown-toggle) {
    visibility: hidden;
    padding: 0px;
    width: 0px;
    height: 0px;
  }
  :global(.dropdown-menu) {
    width: 100%;
    padding: 3px 0px;
    border-color: $super-light;
    max-height: 200px;
    overflow: auto;
  }
  :global(.dropdown-item) {
    padding: 8px 12px;
    &:focus, &:hover, &:active {
      background-color: $theme5;
      cursor: pointer;
    }
  }
  .caretDown {
    position: absolute;
    right: 15px;
    top: 10px;
    color: $dark;
  }
}
.hasSuggestions {
  :global {
    .react-tagsinput {
      border-radius: 4px;
      &:hover {
        border: 1px solid $theme1;
      }
    }
  }
}