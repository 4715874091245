@import '../../../colors.scss';
@import '../../../fonts.scss';
.selectComponent {
  @include body-font;
  width: 424px;
  .dropDown {
    padding: 10px 15px;
  }
  
  &.widthFitParent {
    width: 100%;
  }
  :global {
    .react-select__control {
      border: 1px solid $super-light;
      height: 34px;
      // min-height: 34px;
      &:hover {
        border: 1px solid $theme1;
        cursor: pointer;
      }
    }
    .react-select__control--menu-is-open,
    .react-select__control--is-focused {
      border: 1px solid $theme1;
      box-shadow: none;
    }
    .react-select__multi-value__label {
      white-space: normal;
      padding: 0px;
    }
    .react-select__multi-value {
      @include base-font;
      background-color: $white2;
      border: 1px solid #98969d;
      color: $black;
      margin-right: 5px;
      min-width: 84px;
      min-height: 24px;
      padding-top: 1px;
      padding-bottom: 0px;
      padding-left: 7px;
      margin-left: 0px;
      position: relative;
      display: flex;
      align-items: center;
    }
    .react-select__multi-value__remove {
      flex-shrink: 0;
      border-radius: 50%;
      background-color: $theme2;
      width: 14px;
      height: 14px;
      padding: 0;
      position: relative;
      margin-left: 10px;
      margin-right: 5px;
      > svg {
        position: absolute;
        width: 14px;
        height: 22px;
        color: white;
      }
    }
    .react-select__multi-value__remove:hover {
      background-color: $theme1;
      color: $white;
      cursor: pointer;
    }
    .react-select__option--is-selected {
      background-color: $white;
      color: $black;
    }
    .react-select__option {
      background-color: transparent;
      color: inherit;
      cursor: default;
      display: block;
      font-size: inherit;
      width: 100%;
      user-select: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      box-sizing: border-box;
      padding: 8px 12px;
      &:hover {
        background-color: $theme5;
        cursor: pointer;
        &:active {
          background-color: $theme5;
        }
      }
    }
    .react-select__option--is-focused {
      background-color: $theme5;
      cursor: pointer;
      &:active {
        background-color: $theme5;
      }
    }
    .react-select__indicators {
      height: 34px;
    }
    .react-select__menu {
      z-index: 2;
    }
    .react-select__value-container--is-multi {
      padding-left: 5px;
    }
  }

  &.isMulti {
    :global(.react-select__control) {
      height: auto;
    }
  }
}

:global(.react-select__single-value--is-disabled) {
  color: $super-light !important;
}
